import ContactRep from "@components/contact-rep";
import NavigationList from "@components/navigation-list";
import SiteLogo from "@components/site-logo";
import { IconClose, IconMenu, Link } from "@reusable";
import React, { useEffect, useState } from "react";
import "./styles.scss";

export default function SiteHeader() {
  const [navOpen, setNavOpen] = useState(false);
  // Initialize isFixed state without relying on window
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    // Move window-dependent code inside useEffect
    setIsFixed(window.location.hash === "#tray");

    const checkHash = () => {
      setIsFixed(window.location.hash === "#tray");
    };

    window.addEventListener("hashchange", checkHash, false);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("hashchange", checkHash);
  }, []);

  const onNavToggle = (event) => {
    event.preventDefault();
    setNavOpen(!navOpen);
  };

  return (
    <div
      className={`site-header-container ${
        isFixed ? "fixed-header" : "relative-header"
      }`}
      id="top"
    >
      <div className="site-header-top-links">
        <div className=" container">
          <div className="site-header-top-links-inner-container">
            <p className="site-header-professionals-text">
              This site is for US Healthcare Professionals only.
            </p>
            <div>
              <a
                href="/pdf/rolvedon-prescribing-information.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Prescribing Information
              </a>
              <a href="#tray">Important Safety Information</a>
              <a
                href="https://www.myrolvedon.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Patient Site
              </a>
            </div>
          </div>
        </div>
      </div>
      <header className={`site-header ${isFixed ? "fixed" : ""}`}>
        <div className="site-header-inner-container">
          <nav aria-label="primary" className="site-nav">
            <Link to="/">
              <SiteLogo />
              <span className="visually-hidden">ROLVEDON</span>
            </Link>

            <div className="site-nav--large-screens">
              <NavigationList />
            </div>

            <div className="site-nav--small-screens">
              <ContactRep kind="button" />

              <details open={navOpen}>
                <summary onClick={onNavToggle} style={{ lineHeight: 0 }}>
                  {navOpen ? (
                    <IconClose size="32px" />
                  ) : (
                    <IconMenu size="32px" />
                  )}
                  <span className="visually-hidden">Menu</span>
                </summary>
                <div className="site-nav-dropdown">
                  <NavigationList home={true} hcp={true} small={true} />
                </div>
              </details>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}
