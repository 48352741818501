import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/mdx.js";
import { Link } from "@reusable";
import { IconArrowRight } from "@reusable";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Indications and Usage`}</h2>
    <p>{`ROLVEDON is indicated to decrease the incidence of infection, as manifested by febrile neutropenia, in adult patients with non-myeloid malignancies receiving myelosuppressive anti-cancer drugs associated with clinically significant incidence of febrile neutropenia.`}</p>
    <h6>{`Limitations of Use`}</h6>
    <p>{`ROLVEDON is not indicated for the mobilization of peripheral blood progenitor cells for hematopoietic stem cell transplantation.`}</p>
    <h2>{`Important Safety Information`}</h2>
    <h3>{`Contraindications`}</h3>
    <ul>
      <li parentName="ul">{`ROLVEDON is contraindicated in patients with a history of serious allergic reactions to eflapegrastim, pegfilgrastim or filgrastim products. Reactions may include anaphylaxis.`}</li>
    </ul>
    <h3>{`Warnings and Precautions`}</h3>
    <h4>{`Splenic Rupture`}</h4>
    <ul>
      <li parentName="ul">{`Splenic rupture, including fatal cases, can occur following the administration of recombinant human granulocyte colony-stimulating factor (rhG-CSF) products. Evaluate patients who report left upper abdominal or shoulder pain for an enlarged spleen or splenic rupture.`}</li>
    </ul>
    <h4>{`Acute Respiratory Distress Syndrome (ARDS)`}</h4>
    <ul>
      <li parentName="ul">{`ARDS can occur in patients receiving rhG-CSF products. Evaluate patients who develop fever, lung infiltrates, or respiratory distress. Discontinue ROLVEDON in patients with ARDS.`}</li>
    </ul>
    <h4>{`Serious Allergic Reactions`}</h4>
    <ul>
      <li parentName="ul">{`Serious allergic reactions, including anaphylaxis, can occur in patients receiving rhG-CSF products. Permanently discontinue ROLVEDON in patients who experience serious allergic reactions.`}</li>
    </ul>
    <h4>{`Sickle Cell Crisis in Patients with Sickle Cell Disorders`}</h4>
    <ul>
      <li parentName="ul">{`Severe and sometimes fatal sickle cell crises can occur in patients with sickle cell disorders receiving rhG-CSF products. Discontinue ROLVEDON if sickle cell crisis occurs.`}</li>
    </ul>
    <h4>{`Glomerulonephritis`}</h4>
    <ul>
      <li parentName="ul">{`Glomerulonephritis has occurred in patients receiving rhG-CSF products. The diagnoses were based upon azotemia, hematuria (microscopic and macroscopic), proteinuria, and renal biopsy. Generally, events of glomerulonephritis resolved after dose-reduction or discontinuation. Evaluate and consider dose reduction or interruption of ROLVEDON if causality is likely.`}</li>
    </ul>
    <h4>{`Leukocytosis`}</h4>
    <ul>
      <li parentName="ul">{`White blood cell (WBC) counts of 100 x 10`}<sup className="supUP">{`9`}</sup>{`/L or greater have been observed in patients receiving rhG-CSF products. Monitor complete blood count (CBC) during ROLVEDON therapy. Discontinue ROLVEDON treatment if WBC count of 100 x 10`}<sup className="supUP">{`9`}</sup>{`/L or greater occurs.`}</li>
    </ul>
    <h4>{`Thrombocytopenia`}</h4>
    <ul>
      <li parentName="ul">{`Thrombocytopenia has been reported in patients receiving rhG-CSF products. Monitor platelet counts.`}</li>
    </ul>
    <h4>{`Capillary Leak Syndrome`}</h4>
    <ul>
      <li parentName="ul">{`Capillary leak syndrome has been reported after administration of rhG-CSF products and is characterized by hypotension, hypoalbuminemia, edema and hemoconcentration. Episodes vary in frequency and severity and may be life-threatening if treatment is delayed. If symptoms develop, closely monitor and give standard symptomatic treatment, which may include a need for intensive care.`}</li>
    </ul>
    <h4>{`Potential for Tumor Growth Stimulatory Effects on Malignant Cells`}</h4>
    <ul>
      <li parentName="ul">{`The granulocyte colony-stimulating factor (G-CSF) receptor through which ROLVEDON acts has been found on tumor cell lines. The possibility that ROLVEDON acts as a growth factor for any tumor type, including myeloid malignancies and myelodysplasia, diseases for which ROLVEDON is not approved, cannot be excluded.`}</li>
    </ul>
    <h4>{`Myelodysplastic Syndrome (MDS) and Acute Myeloid Leukemia (AML) in Patients with Breast and Lung Cancer`}</h4>
    <ul>
      <li parentName="ul">{`MDS and AML have been associated with the use of rhG-CSF products in conjunction with chemotherapy and/or radiotherapy in patients with breast and lung cancer. Monitor patients for signs and symptoms of MDS/AML in these settings.`}</li>
    </ul>
    <h4>{`Aortitis`}</h4>
    <ul>
      <li parentName="ul">{`Aortitis has been reported in patients receiving rhG-CSF products. It may occur as early as the first week after start of therapy. Consider aortitis in patients who develop generalized signs and symptoms such as fever, abdominal pain, malaise, back pain, and increased inflammatory markers (e.g., c-reactive protein and white blood cell count) without known etiology. Discontinue ROLVEDON if aortitis is suspected.`}</li>
    </ul>
    <h4>{`Nuclear Imaging`}</h4>
    <ul>
      <li parentName="ul">{`Increased hematopoietic activity of the bone marrow in response to growth factor therapy has been associated with transient positive bone imaging changes. This should be considered when interpreting bone imaging results.`}</li>
    </ul>
    <h3>{`Adverse Reactions`}</h3>
    <ul>
      <li parentName="ul">{`The most common adverse reactions (≥20%) were fatigue, nausea, diarrhea, bone pain, headache, pyrexia, anemia, rash, myalgia, arthralgia, and back pain.`}</li>
      <li parentName="ul">{`Permanent discontinuation due to an adverse reaction occurred in 4% of patients who received ROLVEDON. The adverse reaction requiring permanent discontinuation in 3 patients who received ROLVEDON was rash.`}
        <br /> <br />
        <span className="report">
  <strong>
    To report SUSPECTED ADVERSE REACTIONS, contact Assertio Holdings,
    Inc. at 1&#8209;866&#8209;458&#8209;6389 or FDA at
    1&#8209;800&#8209;FDA&#8209;1088 or{" "}
    <Link affiliated={false} href="https://www.fda.gov/medwatch" mdxType="Link">
      www.fda.gov/medwatch
    </Link>
    .
  </strong>
        </span>
        <br /> <br />
        <a href="/pdf/rolvedon-prescribing-information.pdf" className="prescribing-info-link" target="_blank" rel="noopener noreferrer">
  <span className="report">
    Please see accompanying full Prescribing Information for ROLVEDON.
  </span>
  <IconArrowRight mdxType="IconArrowRight" />
        </a>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      