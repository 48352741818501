import { Button, IconArrowRight, Logo, Modal } from "@reusable";
import React, { useEffect, useState } from "react";
import "./styles.scss";

export default function OnEntryModal() {
  const [isDismissed, setDismissed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const storageKey = "proceed-to-rolontis";
    const value = sessionStorage.getItem(storageKey);

    if (value === "true") {
      setDismissed(true);
    }

    if (isDismissed) {
      sessionStorage.setItem(storageKey, "true");
    }

    // Update isMobile based on the window width when the component mounts
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    // Run on component mount
    handleResize();

    // Listen for window resize to adjust styles for mobile view
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDismissed]);

  // The following useEffect was removed to disable clicking outside of the modal from affecting it.
  /*
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // navigatePatientCaregiver(); // <-- This line is commented out to make it non-functional
      }
    }

    document.addEventListener("mousedown", handleClickOutside); // <-- Added event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // <-- Clean-up listener
    };
  }, []); // <-- End of added effect
  */


  if (isDismissed) {
    return null;
  }

  function dismiss() {
    setDismissed(true);
  }
function navigate() {
    setDismissed(false);
    window.location = "https://www.myrolvedon.com";
  }
  function navigateHealthcareProfessional() {
    setDismissed(true);
  }

  function navigatePatientCaregiver() {
    setDismissed(false);
    window.location = "https://www.myrolvedon.com"; // Example URL
  }

  return (
    <>
    <style>
        {`
          .modal-x {
            display: none !important;
          }
        `}
      </style>
    <Modal handleDismiss={navigatePatientCaregiver}>
      <section
        className="modalx"
        style={{
          textAlign: "center",
        }}
      >
        <p
          style={{
            fontSize: "1.4rem",
            color: "#588ab5",
            fontWeight: "600",
            marginBottom: "0",
          }}
        >
          This website is intended only for healthcare professionals in the
          United States.
        </p>
        <Logo />
        <div
          className="button-group"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: isMobile ? "20px" : "40px", // Adjust gap for mobile view
            flexDirection: isMobile ? "column" : "row", // Stack buttons on mobile
          }}
        >
          <div
            className="button-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              className="button-label"
              style={{
                marginBottom: "10px",
                fontSize: "1.2rem",
                color: "#588ab5",
                fontWeight: "600",
              }}
            >
              I am a healthcare professional
            </p>
            <Button
              onClick={navigateHealthcareProfessional}
              className="btn"
              style={{
                backgroundColor: "#f37c20",
                color: "#fff",
                width: "180px",
                height: "45px",
                display: "flex",
                alignItems: "center",
                fontSize: "small",
                justifyContent: "center",
                borderRadius: "22px !important",
              }}
            >
              Proceed to site <IconArrowRight />
            </Button>
          </div>
          {!isMobile && (
            <div
              className="divider"
              style={{
                width: "2px",
                height: "80px",
                backgroundColor: "#588ab5",
                marginRight: "34px",
              }}
            ></div>
          )}
          <div
            className="button-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              className="button-label"
              style={{
                marginBottom: "10px",
                fontSize: "1.2rem",
                color: "#588ab5",
                fontWeight: "600",
              }}
            >
              I am a patient or caregiver
            </p>
            <Button
              onClick={navigatePatientCaregiver}
              className="btn"
              style={{
                backgroundColor: "#f37c20",
                color: "#fff",
                width: "180px",
                height: "45px",
                display: "flex",
                alignItems: "center",
                fontSize: "small",
                justifyContent: "center",
                borderRadius: "22px !important",
              }}
            >
              Visit Patient site <IconArrowRight />
            </Button>
          </div>
        </div>
      </section>
    </Modal>
    </>
  );
}
