import { Link } from "@reusable";
import React from "react";
import rolontis from "./ROLVEDON-Logo-reg.png";
import spectrum from "./spectrum.svg";
import "./styles.scss";

export default function SiteFooter() {
  return (
    <footer className="site-footer animate-on-enter-3">
      <div className="container">
        <div className="site-footer-layout">
          <Link to="/">
            <img
              src={rolontis}
              alt="rolvedon logo. Click here to go to the homepage"
              className="logo rolvedon-logo"
            />
          </Link>

          <ul>
            <li>
              <a
                href="mailto:rolvedon@assertiotx.com"
                className=""
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
              {/* <Link to="">Contact Us</Link> */}
            </li>
            <li>
              <Link to="/sitemap">Sitemap</Link>
            </li>
            <li>
              <Link
                affiliated={true}
                href="https://www.assertiotx.com/privacy-policy/"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <a
                href="/pdf/ROLVEDON_Returned_Goods_Policy.pdf"
                className=""
                target="_blank"
                rel="noopener noreferrer"
              >
                Return Policy
              </a>
            </li>
            <li>
              <Link
                affiliated={true}
                href="https://www.assertiotx.com/terms-of-use/"
              >
                Terms of Use
              </Link>
            </li>
            <li>
    <a href="#" id="cookie-settings-link" class="ot-sdk-show-settings">Cookies Settings</a>
  </li>
          </ul>

          <Link affiliated={true} href="https://www.sppirx.com">
            <img src={spectrum} alt="spectrum pharmaceuticals logo. Click here to go to the website" className="logo" />
          </Link>
        </div>

        <p>
        ROLVEDON and ACCESS4Me are registered trademarks of Spectrum Pharmaceuticals, Inc. ©&nbsp;2024&nbsp;Spectrum Pharmaceuticals, Inc. A subsidiary of Assertio Holdings, Inc. All rights reserved.{" "}
          <span className="no-wrap">PP-ROL-00-0678</span>
        </p>
      </div>
    </footer>
  );
}
