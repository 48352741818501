import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/mdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="fixedISI">
  <div className="flex-container">
    <div className="flex-item indication">
      <h2 className="isi-heading">Indication</h2>
      <p className="isi-paragraph">
        ROLVEDON is indicated to decrease the incidence of infection, as
        manifested by febrile neutropenia, in adult patients with non-myeloid
        malignancies receiving myelosuppressive anti-cancer drugs associated
        with clinically significant incidence of febrile neutropenia.
      </p>
    </div>
    <div className="flex-item safety-info">
      <h2 className="isi-heading">Important Safety Information</h2>
      <h3 className="isi-subheading">Contraindications</h3>
      <ul>
        <li className="isi-list-item">
          ROLVEDON is contraindicated in patients with a history of serious
          allergic reactions to eflapegrastim, pegfilgrastim, or filgrastim
          products. Reactions may include anaphylaxis.
        </li>
      </ul>
    </div>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      