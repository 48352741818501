import OnEntryModal from "@components/on-entry-modal";
import SiteFooter from "@components/site-footer";
import SiteHeader from "@components/site-header";
import UsageTray from "@components/usage-tray";
import PageHeader from "@reusable/page-header";
import React from "react";
import Helmet from "react-helmet";
import "../../static/custom.min.css";
import "../styles/main.scss";

const BaseLayout = ({ children, ...props }) => {
  const frontmatter = props.pageContext ? props.pageContext.frontmatter : {};
  const {
    path,
    title,
    description,
    canonicalUrl,
    ogtitle,
    twittertitle,
    ogDescription,
    twitterDescription,
  } = frontmatter;

  const isHomepage = path === "/";

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <html lang="en" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />

        {/* Browser Bar on Mobile */}
        <meta name="theme-color" content="#87189D" />
        {/* END Browser Bar on Mobile */}

        {/* Titles */}
        <title>
          {isHomepage
            ? "ROLVEDON® (eflapegrastim-xnst) Possibly Reduce Infections"
            : path === "/sitemap"
            ? "HTML Sitemap - Find A Page - ROLVEDON® (eflapegrastim-xnst)"
            : title}
        </title>
        <meta
          property="og:title"
          content={
            isHomepage
              ? "ROLVEDON® (eflapegrastim-xnst) Possibly Reduce Infections"
              : path === "/sitemap"
              ? "HTML Sitemap - Find A Page - ROLVEDON® (eflapegrastim-xnst)"
              : ogtitle
          }
        />
        <meta
          name="twitter:title"
          content={
            isHomepage
              ? "ROLVEDON® (eflapegrastim-xnst) Possibly Reduce Infections"
              : path === "/sitemap"
              ? "HTML Sitemap - Find A Page - ROLVEDON® (eflapegrastim-xnst)"
              : twittertitle
          }
        />

        <meta
          name="description"
          content={
            isHomepage
              ? "ROLVEDON®; reduce the risk of infection as manifested by febrile neutropenia in adult patients with non-myeloid malignancies receiving chemotherapy"
              : path === "/sitemap"
              ? "Explore our HTML sitemap for easy navigation and find all the important pages on our website"
              : description
          }
        />
        <meta
          property="og:description"
          content={
            isHomepage
              ? "ROLVEDON®; reduce the risk of infection as manifested by febrile neutropenia in adult patients with non-myeloid malignancies receiving chemotherapy"
              : path === "/sitemap"
              ? "Explore our HTML sitemap for easy navigation and find all the important pages on our website"
              : ogDescription
          }
        />
        <meta
          name="twitter:description"
          content={
            isHomepage
              ? "ROLVEDON®; reduce the risk of infection as manifested by febrile neutropenia in adult patients with non-myeloid malignancies receiving chemotherapy"
              : path === "/sitemap"
              ? "Explore our HTML sitemap for easy navigation and find all the important pages on our website"
              : twitterDescription
          }
        />

        <link
          rel="canonical"
          href={isHomepage ? "https://www.rolvedon.com" : canonicalUrl}
        />
        <meta
          property="og:url"
          content={isHomepage ? "https://www.rolvedon.com" : canonicalUrl}
        />
        <meta
          name="twitter:url"
          content={isHomepage ? "https://www.rolvedon.com" : canonicalUrl}
        />

        <meta property="og:site_name" content="ROLVEDON HCP" />

        <meta itemprop="name" content="ROLVEDON HCP" />
        <meta
          itemprop="description"
          content="ROLVEDON®; reduce the risk of infection as manifested by febrile neutropenia in adult patients with non-myeloid malignancies receiving chemotherapy"
        />

        {/* Social Image */}
        <meta
          property="og:image"
          content="https://www.rolvedon.com/ROLVEDON-Logo-reg.png"
        />

        <meta
          name="twitter:image"
          content="https://www.rolvedon.com/ROLVEDON-Logo-reg.png"
        />
        <meta
          itemprop="image"
          content="https://www.rolvedon.com/ROLVEDON-Logo-reg.png"
        />
        {/* END Social Image */}

        {/* The way things look */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-T8Gy5hrqNKT+hzMclPo118YTQO6cYprQmhrYwIiQ/3axmI1hQomh7Ud2hPOy8SP1"
          crossorigin="anonymous"
        />
        {/* END The way things look */}

        {/* Favicon */}
        <link rel="icon" type="image/png" href="/assets/images/favicon.png" />
        {/* END Favicon */}
      </Helmet>

      <OnEntryModal />
      <SiteHeader />

      {/* Main content */}
      <main className="main-layout">
        {title && <PageHeader {...frontmatter} />}
        {path === "/sitemap" && (
          <PageHeader title="Site Map" {...frontmatter} />
        )}
        {children}
      </main>
      {/* END Main content */}

      <UsageTray>
        <SiteFooter />
      </UsageTray>
    </>
  );
};

export default BaseLayout;
