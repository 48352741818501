import {
  IconArrowRight,
  Tray,
  TrayContent,
  TrayIsFixed,
  TrayTab,
} from "@reusable";
import React, { useEffect, useState } from "react";
import UsageTabContent from "../../mdx/usage-tab.mdx";
import UsageContent from "../../mdx/usage.mdx";
import "./styles.scss";

export default function UsageTray({ children }) {
  // Initialize showLess without relying on window
  const [showLess, setShowLess] = useState(false);

  useEffect(() => {
    // Adjust showLess based on the current URL hash
    setShowLess(window.location.hash === "#tray");

    const checkHashForShowLess = () => {
      setShowLess(window.location.hash === "#tray");
    };

    window.addEventListener("hashchange", checkHashForShowLess, false);

    // Cleanup by removing the event listener when the component unmounts
    return () => window.removeEventListener("hashchange", checkHashForShowLess);
  }, []);

  return (
    <Tray>
      <TrayTab>
        <div className="container">
          <div className="usage-tab-layout">
            <TrayIsFixed>
              {(fixed) => (
                <>
                  {fixed && (
                    <a href="#tray" className="show-more">
                      <IconArrowRight size={32} className="icon-show-more" />
                      <span className="large-only">Show More</span>
                    </a>
                  )}
                </>
              )}
            </TrayIsFixed>
            <UsageTabContent />
          </div>
        </div>
      </TrayTab>

      <TrayContent>
        <div className="container">
          {showLess && (
            <div className="showless">
              <a href="#top">
                <span className="showless-text">Show Less</span>
                <IconArrowRight size={32} className="icon-show-more" />
              </a>
            </div>
          )}
          <UsageContent />
        </div>
        {children}
      </TrayContent>
    </Tray>
  );
}
